<template>
  <div class="main-container">
    <!-- <div class="main-container-nav">
      <NavBar />
    </div> -->
    <div class="main-conversation">
      <transition :name="$store.state.direction">
        <keep-alive v-if="$route.meta.keepAlive">
          <router-view class="router"></router-view>
        </keep-alive>
        <router-view class="router" v-else></router-view>
      </transition>
    </div>
    <PopLargerData />
    <PopupQuestionList />
    <PageLoading />
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'indexBase',
  components: {
    NavBar: () => import('@/components/NavBar/index.vue'),
    Tabbar: () => import('@/components/Tabbar/index.vue'),
    PopLargerData: () => import('@/components/PopLargerData/index.vue'),
    PopupQuestionList: () => import('@/components/PopupQuestionList/index.vue'),
    PageLoading: () => import('@/components/PageLoading/index.vue'),
  },
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  methods: {},
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
// .router {
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   overflow-x: hidden;
//   overflow-y: auto;
//   -webkit-overflow-scrolling: touch;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
// }
.main-container {
  // background-color: rgba(247, 248, 251, 1);
  // background-color: #f1f2f6;
  .main-container-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 1;
  }
  .main-conversation {
    overflow-y: scroll;
    // padding-bottom: 80px;
    margin-top: 44px;
  }
}
</style>
